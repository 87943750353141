<template>
  <div class="heading">
    <h1 v-if="!testimonialId">Add Testimonial</h1>
    <h1 v-if="testimonialId">Edit Testimonial</h1>
  </div>
  <div style="float: left;display: inline-flex">
    <el-button @click="testimonials()">
      Testimonials
    </el-button>
  </div>
  <div v-if="showAlertAdded">
    <el-alert
      title="Testimonial Added Successfully"
      type="success"
      effect="dark"
      v-if="!testimonialId"
    />
    <el-alert
        title="Testimonial Updated Successfully"
        type="success"
        effect="dark"
        v-if="testimonialId"
    />
  </div>
  <div v-if="showAlertSlugexit">
    <el-alert
      title="The slug has already been taken."
      type="error"
      effect="dark"
    />
  </div>
  <div class="login">
    <el-form
      ref="ruleForm"
      :model="data"
      status-icon
      :rules="rules"
      label-width="180px"
      class=" login-form"
    >
      <el-form-item
        label="Title"
        prop="Title"
      >
        <el-input
          v-model="data.title"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        label="Name"
        prop="name"
      >
        <el-input
          v-model="data.name"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        label="Description"
        prop="description"
      >
       <TinyMce
              v-if="loadEditor"
              v-bind:content="data.description"
              @contentChanged="updateTextAreaValue"
          >
          </TinyMce>
      </el-form-item>
      <el-form-item
        label="Video URL"
        prop="video_url"
      >
        <el-input
          v-model="data.video_url"
          type="text"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item
        label="Meta Tag Title"
        prop="meta_title"
      >
      <el-input
          v-model="data.meta_title"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
        label="Meta Tag Description"
        prop="meta_description"
      > 
      <el-input
          v-model="data.meta_description"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
        label="Meta Tag Keywords"
        prop="meta_keyword"
      >
      <el-input
          v-model="data.meta_keyword"
          type="textarea"
        />
      </el-form-item>
      <el-form-item
      label="Status"
      prop="status">
      <el-select v-model="data.status">
    <!--Add options when value is id-->
    <el-option v-for="item in selectstatus" 
            :key="item.label"
            :value="item.value"
            :label="item.label"
    >
      {{item.label}}
    </el-option>
  </el-select>
  </el-form-item>
      
      <el-form-item>
        <input
          id="testimonialId"
          v-model="testimonialId"
          name="testimonialId"
          type="hidden"
        >
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Submit
        </el-button>
        <el-button @click="resetForm('ruleForm')"  v-if="!testimonialId">
          Reset
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import TestimonialsService from "@/api/testimonials.service";
import authHeader from '@/api/auth-header';
import TinyMce from "@/components/TinyMce";

  export default {
    components: {
      TinyMce
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Testimonial Name.'));
        } else {
          callback();
        }
      };
      var checkDescription = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Testimonial Description.'));
        } else {
          callback();
        }
      };
      var checkSlug = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the Slug.'));
        } else {
          callback();
        }
      };
      var checkMetatitle = (rule, value, callback) => {        
          callback();
      };
      return {
        showAlertAdded: false,
        showAlertSlugexit: false,
        testimonialId: this.$route.params.testimonialId,
		selectstatus:[{
          value: 'Enabled',
          label: 'Enabled'
        }, {
          value: 'Disabled',
          label: 'Disabled'
        }],
        fileList: [],
        data: {
          titile: null,
          name: null,
          description: null,
          video_url: null,
          meta_title: null,
          meta_description: null,
          meta_keyword: null,
          created_at: null,
          status: null,
        },
        // ruleForm: {
        //   name: '',
        //   image: ''
        // },
        rules: {
          name: [
            { validator: checkName, trigger: 'blur' }
          ],
          description: [
            { validator: checkDescription, trigger: 'blur' }
          ],
         
          slug: [
            { validator: checkSlug, trigger: 'blur' }
          ]
        }
      };
    },
	mounted() {
    this.setImageUploadUrl()
    if(this.testimonialId){
      this.gettestimonial();
    }
  },  
  computed: {
      loadEditor: function () {
        return !this.testimonialId || (this.testimonialId && this.data.description);
        },
  },
    methods: {
       updateTextAreaValue(value) {
        this.data.description = value;
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid && !this.testimonialId) {
             return TestimonialsService.add(this.data).then(response => {
                // this.data = response.data
                this.showAlertAdded=true 
                return response
              });
          } else if (valid && this.testimonialId) {
            return TestimonialsService.update(this.testimonialId, this.data).then(response => {
				console.log(response);
              this.showAlertAdded=true;
              return response;

            });
          }else {
			this.showAlertSlugexit=true
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
	testimonials(){
		this.$router.push("/testimonial");
	},
	gettestimonial(){
    return TestimonialsService.get(this.testimonialId).then(response => {
        this.data = response.data.data;
        this.fileList.push({name:'', url: response.data.data.image});
        return response;
      });
	},
      setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response, file, fileList) {
        if(response.code == 200) {
          this.data.imageNew = response.image_url;
        }
      },
      handleImageUploadError(err, file, fileList) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },
    }
  }
</script>
<style lang="scss" scoped>
.heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}

    .login {
        flex: 1;
		width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   .login-form {
        width: 100%;
    }
    .selectstatus{
      -webkit-appearance: none;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
    }
</style>
